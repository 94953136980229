import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Container from 'components/ui/Container';

import TitleSection from 'components/ui/TitleSection';
import { ContentList, ContentListItem, ListType } from 'components/ListPage/list-page';

interface Props {
  data: {
    allMarkdownRemark: {
      edges: {
        node: {
          fields: {
            slug: string;
          };
          frontmatter: ContentListItem;
        };
      }[];
    };
  };
  pageContext: {
    collection: string;
    tag: string;
  };
}

const TagPage: React.FC<Props> = ({ data, pageContext }) => {
  const { tag, collection } = pageContext;

  const items = data.allMarkdownRemark.edges.map(
    (edge): ContentListItem => {
      return {
        ...edge.node.frontmatter,
        slug: edge.node.fields.slug,
      };
    },
  );
  return (
    <Layout>
      <SEO title={`${tag} - ${collection}`} />

      <Container section className="items-center justify-center">
        <TitleSection subtitle={`${collection} with tag`} title={tag} center />
        <ContentList items={items} listType={ListType.CARD} />
        {/* <PostList posts={data.allMarkdownRemark.edges} /> */}
      </Container>
    </Layout>
  );
};

export default TagPage;

export const query = graphql`
  query PostsByTag($tag: String!, $collection: String!) {
    allMarkdownRemark(
      filter: {
        frontmatter: { tags: { in: [$tag] }, category: { ne: "dummy" } }
        fields: { collection: { eq: $collection } }
        published: { eq: true }
      }
    ) {
      edges {
        node {
          fields {
            slug
            collection
          }
          frontmatter {
            title
            description
            date(formatString: "MMM DD, YYYY")
            tags
            cover {
              childImageSharp {
                fluid(maxWidth: 800, quality: 60, cropFocus: CENTER) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
          published
        }
      }
    }
  }
`;
